import React, { useEffect, useMemo, useRef, useState } from 'react'
import { generateHMAC } from './hmac'
import { useTokenexIframeScript } from './use-token-iframe-script'
import { css, styles } from './iframe-styles'

/**
 * @typedef {{ scheme: 'PCI' token: string, mode?: 'PAN' | 'CVV' }} Props
 */

let instanceId = 0
/**
 * @param {Props} props
 */
export function TokenexDetokenizer({ scheme, token, mode }) {
  const script = useTokenexIframeScript()
  const config = useTokenexIframeConfig({ scheme, token, mode })
  const el = useRef()
  const id = useMemo(() => `tokenex-${instanceId++}`, [])

  const [statusText, setStatusText] = useState('loading')

  useEffect(() => {
    if (script.status === 'done' && el && config) {
      setStatusText('')

      const iframe = new window.TokenEx.DetokenizeIframe(id, {
        ...config,
        styles
      })
      iframe.on('expired', () => setStatusText('•••'))
      iframe.load()

      return () => iframe.remove()
    }
  }, [script.status, config, el, id])

  return (
    <>
      {statusText && (
        <b>
          &nbsp;
          {statusText}
        </b>
      )}
      <span className={css.iframe} ref={el} id={id} />
    </>
  )
}

/**
 * @param {Props} props
 */
function useTokenexIframeConfig({ token, mode }) {
  const tokenExID = process.env.UPD_TOKENEX_VAULT_ID
  const timestamp = new Date()
    .toISOString()
    .replace(/[^0-9]/g, '')
    .slice(0, -3)
  const origin = location.origin

  const [authenticationKey, setAuthenticationKey] = useState()
  useEffect(() => {
    generateHMAC(
      process.env.UPD_TOKENEX_IFRAME_KEY,
      [tokenExID, origin, timestamp, token].join('|')
    )
      .then(setAuthenticationKey)
      .catch(e => console.error(e))
  }, [])

  return useMemo(
    () =>
      !authenticationKey
        ? undefined
        : {
            token,
            tokenExID,
            authenticationKey,
            timestamp,
            origin,
            debug: true,
            expiresInSeconds: 60,
            ...(mode === 'CVV' && {
              cvv: true,
              cvvOnly: true
            })
          },
    [authenticationKey]
  )
}
