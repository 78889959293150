import React from 'react'
import creditCardType from 'credit-card-type'
import { TokenexDetokenizer } from './tokenex/tokenex-detokenizer'

/**
 * @param {import('./homeServicesOrder/types').DTSPayload['payment']['creditCard']} props
 */
export function CreditCard(props) {
  return props.tokenizer === 'tokenex' ? <TokenexCreditCard {...props} /> : <ClassicCreditCardPayload {...props}/>
}

/**
 * @param {import('./homeServicesOrder/types').TokenexPayload['payment']['creditCard']} props
 */
export function TokenexCreditCard({
  token,
  expirationDate,
  firstName,
  lastName
}) {
  const [network] = creditCardType(token)

  return (
    <>
      <div>
        Card Number:{' '}
        <TokenexDetokenizer scheme="PCI" mode="PAN" token={token} />
      </div>
      <div>
        Card Type:{' '}
        {network ? network.niceType : 'Unknown, please context support.'}
      </div>
      <div>Expiration: {expirationDate}</div>
      <div>
        CVV: <TokenexDetokenizer scheme="PCI" mode="CVV" token={token} />
      </div>
      <div>
        Name on Card: {firstName}&nbsp;
        {lastName}
      </div>
    </>
  )
}

/**
 * @param {import('./homeServicesOrder/types').ClassicPayload['payment']['creditCard']} props
 */
function ClassicCreditCardPayload(props) {
  const [network] = creditCardType(props.number)

  return (
    <>
      <div>Card Number: {props.number}</div>
      <div>Card Type: {network ? network.niceType : 'unknown'}</div>
      <div>Expiration: {props.expirationDate}</div>
      <div>CVV: {props.securityCode}</div>
      <div>
        Name on Card: {props.firstName}&nbsp;
        {props.lastName}
      </div>
    </>
  )
}
