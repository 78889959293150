import {
  ArrayInput,
  AutocompleteInput,
  TopToolbar,
  CloneButton,
  Create,
  Datagrid,
  Edit,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  email,
  required
} from 'react-admin'
import React, { Fragment } from 'react'
import { BannerHtmlInput } from './fragments/banner_html'
import { RentersInsuranceInput } from './fragments/renters_insurance'
import { ResidentPortalInput } from './fragments/resident_portal'
import { LogoUploadInput } from './fragments/logo_upload'
import { UtilitiesInput } from './fragments/utilities'

import { sanitizer } from './formatters/sanitizer'
import { withStyles } from '@material-ui/core'
import LinkResourceField from './fields/linkResourceField'

const inputStyle = {
  config_container: {
    'margin-top': '1em',
    padding: '1em',
    'box-shadow': '0px 1px 3px 0px rgba(0, 0, 0, 0.2)'
  },
  config_title: {
    color: 'rgba(0, 0, 0, 0.87)',
    'margin-bottom': '1em'
  }
}

const DivisionFilter = props => (
  <Filter {...props}>
    <TextInput label="name" source="name" defaultValue="" />
    <TextInput label="id" source="id" defaultValue="" />
    <TextInput label="Parent ID" source="parent_id" defaultValue="" />
    <TextInput label="Full text" source="full_text" defaultValue="" />
  </Filter>
)

export const DivisionList = props => (
  <List {...props} filters={<DivisionFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <FunctionField
        label="External IDs"
        render={record => `${record.external_ids.join(', ')}`}
      />
      <LinkResourceField
        label="Company"
        source="parent_id"
        reference="companies"
        display="parent_id"
      />
      <TextField source="name" label="Name" />
      <TextField source="display_name" label="Display Name" />
      <TextField source="status" label="Status" />
      <TextField source="number_of_units" label="Number of Units" />
      <TextField source="address.street" label="Street" />
      <TextField source="address.city" label="City" />
      <TextField source="address.state" label="State" />
      <TextField source="address.zip" label="Postal Code" />
      <TextField source="phone" label="Phone" />
      <TextField source="website" label="Website" />
      <TextField source="facebook_url" label="Facebook URL" />
      <TextField source="twitter_url" label="Twitter URL" />
      <CloneButton />
    </Datagrid>
  </List>
)

const DivisionInput = withStyles(inputStyle)(({ classes, record }) => (
  <Fragment>
    <ArrayInput source="external_ids">
      <SimpleFormIterator>
        <TextInput
          label="External ID"
          format={sanitizer}
          validate={required()}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput
      label="name"
      source="name"
      options={{ fullWidth: true }}
      validate={required()}
    />
    <SelectInput
      label="Status"
      source="status"
      choices={[
        { id: 'new', name: 'New' },
        { id: 'onboarding', name: 'Onboarding' },
        { id: 'onboarding_complete', name: 'Onboarding Complete' },
        { id: 'live', name: 'Live' },
        { id: 'paused', name: 'Paused' },
        { id: 'inactive', name: 'Inactive' }
      ]}
      options={{ fullWidth: true }}
    />
    <NumberInput label="Number of Units" source="number_of_units" />
    <TextInput
      label="Address - Street"
      source="address.street"
      options={{ fullWidth: true }}
    />
    <TextInput label="Address - City" source="address.city" />
    <TextInput label="Address - State" source="address.state" />
    <TextInput label="Address - Postal Code" source="address.zip" />
    <TextInput multiline label="Invite Message" source="message" />
    <TextInput
      label="Email"
      source="email"
      validate={email()}
      options={{ fullWidth: true }}
    />
    <TextInput label="Phone" source="phone" />
    <TextInput label="Website" source="website" options={{ fullWidth: true }} />
    <TextInput
      label="Facebook"
      source="facebook_url"
      options={{ fullWidth: true }}
    />
    <TextInput
      label="Twitter"
      source="twitter_url"
      options={{ fullWidth: true }}
    />

    <LogoUploadInput record={record} />

    <div className={classes.config_container}>
      <div className={classes.config_title}>Config</div>
      <ResidentPortalInput />
      <RentersInsuranceInput record={record} />
      <BannerHtmlInput />
      <UtilitiesInput />
    </div>
  </Fragment>
))

const DivisionEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <CloneButton record={data} basePath={basePath} />
  </TopToolbar>
)

export const DivisionEdit = props => (
  <Edit title="Edit Division" {...props} actions={<DivisionEditActions />}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <DivisionInput />
    </SimpleForm>
  </Edit>
)

export const DivisionCreate = props => (
  <Create title="Create Division" {...props}>
    <SimpleForm>
      <ReferenceInput
        label="Parent"
        source="parent_id"
        reference="companies"
        filterToQuery={searchText => ({ full_text: searchText })}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput source="name" optionText="name" />
      </ReferenceInput>
      <DivisionInput />
    </SimpleForm>
  </Create>
)
