import {
  ArrayInput,
  TopToolbar,
  CloneButton,
  Create,
  Datagrid,
  Edit,
  Filter,
  FunctionField,
  List,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  email,
  required
} from 'react-admin'
import React, { Fragment } from 'react'
import { url } from 'redux-form-validators' //TODO: ra3
import { BannerHtmlInput } from './fragments/banner_html'
import { RentersInsuranceInput } from './fragments/renters_insurance'
import { HomeownerInsuranceInput } from './fragments/homeowner_insurance'
import { ResidentPortalInput } from './fragments/resident_portal'
import { LogoUploadInput } from './fragments/logo_upload'
import { UtilitiesInput } from './fragments/utilities'
import { FeaturesInput } from './fragments/features'
import { sanitizer } from './formatters/sanitizer'
import { withStyles } from '@material-ui/core'

const inputStyle = {
  config_container: {
    'margin-top': '1em',
    padding: '1em',
    'box-shadow': '0px 1px 3px 0px rgba(0, 0, 0, 0.2)'
  },
  config_title: {
    color: 'rgba(0, 0, 0, 0.87)',
    'margin-bottom': '1em'
  }
}
const CompanyFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" defaultValue="" />
    <TextInput label="ID" source="id" defaultValue="" />
    <TextInput label="Subdomain" source="subdomain" defaultValue="" />
    <TextInput label="Code" source="code" defaultValue="" />
    <TextInput label="Full Text" source="full_text" defaultValue="" />
  </Filter>
)

export const CompanyList = props => (
  <List {...props} filters={<CompanyFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <FunctionField
        label="External IDs"
        render={record => `${record.external_ids.join(', ')}`}
      />
      <TextField source="name" label="Name" />
      <TextField source="display_name" label="Display Name" />
      <TextField source="kind" label="Kind" />
      <TextField source="phone" label="Phone" />
      <TextField source="website" label="Website" />
      <TextField source="facebook_url" label="Facebook URL" />
      <TextField source="twitter_url" label="Twitter URL" />
      <CloneButton />
    </Datagrid>
  </List>
)

const CompanyInput = withStyles(inputStyle)(({ classes, record }) => (
  <Fragment>
    <ArrayInput source="external_ids">
      <SimpleFormIterator>
        <TextInput
          label="External ID"
          format={sanitizer}
          validate={required()}
        />
      </SimpleFormIterator>
    </ArrayInput>
    <TextInput
      label="name"
      source="name"
      options={{ fullWidth: true }}
      validate={required()}
    />
    <TextInput
      label="Display Name"
      source="metadata.display_name"
      options={{ fullWidth: true }}
    />
    <TextInput
      label="Subdomain"
      source="subdomain"
      options={{ fullWidth: true }}
    />
    <TextInput label="Code" source="code" options={{ fullWidth: true }} />
    <TextInput multiline label="Invite Message" source="message" />
    <TextInput
      label="Email"
      source="email"
      validate={email()}
      options={{ fullWidth: true }}
    />
    <TextInput label="Phone" source="phone" />
    <TextInput
      label="Website"
      source="website"
      options={{ fullWidth: true }}
      validate={url({ allowBlank: true })}
    />
    <TextInput
      label="Description"
      source="metadata['description']"
      options={{ fullWidth: true }}
    />
    <TextInput
      label="Facebook"
      source="facebook_url"
      options={{ fullWidth: true }}
    />
    <TextInput
      label="Twitter"
      source="twitter_url"
      options={{ fullWidth: true }}
    />
    <LogoUploadInput record={record} />
    <div className={classes.config_container}>
      <div className={classes.config_title}>Config</div>
      <ResidentPortalInput />
      <RentersInsuranceInput record={record} />
      <HomeownerInsuranceInput record={record} />
      <BannerHtmlInput />
      <UtilitiesInput />
      <FeaturesInput />
    </div>
  </Fragment>
))

export const CompanyCreate = props => (
  <Create title="Create Company" {...props}>
    <SimpleForm>
      <SelectInput
        label="Kind"
        source="kind"
        choices={[
          { id: 'affiliate', name: 'Affiliate' },
          { id: 'brokerage', name: 'Brokerage' },
          { id: 'business_partner', name: 'Business Partner' },
          { id: 'moving', name: 'Moving' },
          { id: 'property_management', name: 'Property Management' },
          { id: 'student_housing', name: 'Student Housing' },
          { id: 'title', name: 'Title' },
          { id: 'truck_rental', name: 'Truck Rental' },
          { id: 'utility', name: 'Utility' }
        ]}
        options={{ fullWidth: true }}
      />
      <CompanyInput />
      <SelectInput
        label="Subscription Type"
        source="subscription_type"
        choices={[
          { id: 'standard', name: 'Standard' },
          { id: 'premium', name: 'Premium' },
          { id: 'none', name: 'None' },
          { id: 'disabled', name: 'Disabled' }
        ]}
        defaultValue={'disabled'}
        options={{ fullWidth: true }}
      />
      <TextInput
        disabled
        label="Subscription Plan"
        source="subscription_plan_id"
        defaultValue={'1'}
      />
    </SimpleForm>
  </Create>
)

const CompanyEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <CloneButton record={data} basePath={basePath} />
  </TopToolbar>
)

export const CompanyEdit = props => (
  <Edit title="Edit Company" {...props} actions={<CompanyEditActions />}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <CompanyInput />
    </SimpleForm>
  </Edit>
)
