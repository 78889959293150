import buildGraphQLProvider from 'ra-data-graphql'
import { createHttpLink } from 'apollo-link-http'
import { fetchUtils } from 'react-admin'
import jsonAPIDataProvider from './jsonAPIDataProvider'
import buildQuery from './graphqlDataProvider'
import configServiceDataProvider from './configServiceDataProvider'
import addLogoUploadFeature from './logoUploadFeature'

const { UPD_API_URL, UPD_CONFIG_SERVICE_URL } = process.env
export const apiUrl = UPD_API_URL

const jsonAPIUrl = `${apiUrl}/v2`
const graphQLUrl = `${apiUrl}/graphql`

const uid = () => sessionStorage.getItem('uid')
const client = () => sessionStorage.getItem('client')
const accessToken = () => sessionStorage.getItem('access-token')
const edwinJwt = () => sessionStorage.getItem('edwinJwt')

const setHeaders = (headers = {}) => {
  if (uid()) headers.uid = uid()
  if (accessToken()) headers['Access-Token'] = accessToken()
  if (client()) headers.client = client()

  headers.app = 'jarvis'
  return headers
}

const refreshHeaders = headers => {
  setTimeout(() => {
    if (!headers.uid && !uid()) {
      refreshHeaders(headers)
    } else {
      setHeaders(headers)
    }
  }, 500)
}

const graphQLHeaders = () => {
  const headers = {}
  refreshHeaders(headers)
  return headers
}

const graphqlLink = () =>
  new createHttpLink({
    uri: graphQLUrl,
    headers: graphQLHeaders()
  })

const jsonAPIHeaders = () => {
  const headers = new Headers(setHeaders())
  headers.set('Content-Type', 'application/json')
  headers.set('Accept', 'application/vnd.api+json')
  return headers
}

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = jsonAPIHeaders()
  }
  return fetchUtils.fetchJson(url, options)
}

const buildUPDGraphQLDataProvider = () =>
  buildGraphQLProvider({
    buildQuery,
    clientOptions: { link: graphqlLink() }
  })

const updJsonApiDataProvider = () =>
  addLogoUploadFeature(jsonAPIDataProvider(jsonAPIUrl, httpClient))

const configServiceHttpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  options.headers.set('Content-Type', 'application/json')
  options.headers.set('Authorization', `Bearer ${edwinJwt()}`)
  return fetchUtils.fetchJson(url, options)
}

const updConfigServiceDataProvider = () =>
  configServiceDataProvider(UPD_CONFIG_SERVICE_URL, configServiceHttpClient)

export {
  buildUPDGraphQLDataProvider,
  updJsonApiDataProvider,
  updConfigServiceDataProvider,
  httpClient,
  jsonAPIUrl
}
