export { default as css } from './tokenex.module.css'

const css = (ss, ...vs) =>
  ss.reduce((s, v, i) => `${s}${v}${i < vs.length ? vs[i] : ''}`, '')

const base = css`
  margin: 0;
  border: none;
  width: min-content;
  height: max-content;
  padding: 0;

  font-size: 1rem;
  line-height: 1rem;
  height: 1rem;
  box-sizing: border-box;
  display: inline;
  vertical-align: bottom;
  overflow: visible;

  background-color: transparent;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #000;
`

const focus = css`
  ${base}
  box-shadow: 0 0 6px 0 rgba(0, 132, 255, 0.5);
  border: 1px solid rgba(0, 132, 255, 0.5);
  outline: 0;
`
const error = css`
  ${base}
  box-shadow: 0 0 6px 0 rgba(224, 57, 57, 0.5);
  border: 1px solid rgba(224, 57, 57, 0.5);
`
export const styles = { base, focus, error, cvv: { base, focus, error } }
