import React, { Fragment, useState } from 'react'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Radio,
  FormControlLabel,
  Button
} from '@material-ui/core'
import { Checkbox } from '@material-ui/core'

import Modal from '../modal'
import PackageInfo from '../packageInfo'
import UserInfo from '../userInfo'
import Activity from '../activity'
import { DEPOSIT_AMOUNT } from './constants'
import { CreditCard } from '../../../credit-card'

const EquipmentOptions = ({
  classes,
  record: { installation: { modem_choice = '' } = {} } = {}
}) => (
  <Fragment>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={modem_choice.toLowerCase() === 'internetrental'}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="xFi Gateway ($15.00 / month)"
        disabled
        classes={{ label: classes.label }}
      />
      <p className={classes.optionSubtext}>
        Customer understands that the price may increase to up to $25/mo after
        the first 12-24 months
      </p>
    </div>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={modem_choice.toLowerCase() === 'internetmodemowned'}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="I’ll use my own"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
  </Fragment>
)

const IdentityVerification = ({
  classes,
  creditCheck: { creditCheckMethod = '', dateOfBirth } = {}
}) => (
  <Fragment>
    <div>
      <h3>Payment type</h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={creditCheckMethod.toLowerCase() === 'autopay'}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="Auto pay"
          labelPlacement="end"
          classes={{ label: classes.label }}
          disabled
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={creditCheckMethod.toLowerCase() === 'deposit'}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="Deposit"
          labelPlacement="end"
          classes={{ label: classes.label }}
          disabled
        />
      </div>
    </div>
    <div>
      <h3>Date of birth</h3>
      <div>{dateOfBirth}</div>
    </div>
  </Fragment>
)

const InstallationInformation = ({ classes }) => (
  <Fragment>
    <div>
      <h3>Choose type of installation</h3>
      <FormControlLabel
        control={
          <Checkbox checked={true} classes={{ disabled: classes.disabled }} />
        }
        label="Self-Install"
        labelPlacement="end"
        classes={{ label: classes.label }}
        disabled
      />
    </div>
    <div>
      <h3>Shipping type</h3>
      <p>Getting started kit, standard shipping fee up to $39.99</p>
      <p className={classes.subtext}>
        Proceed with ordering processing even if actual shipping cost is less
        than $39.99
      </p>
    </div>
  </Fragment>
)

const BillingInfo = ({
  payment: { creditCard = {} } = {},
  creditCheck: { creditCheckMethod = '' } = {},
  price: { items = {} } = {},
  classes
}) => {
  const { billingAddress = {} } = creditCard
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const paymentAmount =
    creditCheckMethod.toLowerCase() === 'autopay'
      ? items.autopay.price.toFixed(2)
      : creditCheckMethod.toLowerCase() === 'deposit'
      ? DEPOSIT_AMOUNT.toFixed(2)
      : ''
  const paymentKind =
    creditCheckMethod.toLowerCase() === 'autopay'
      ? 'pre-payment'
      : creditCheckMethod.toLowerCase() === 'deposit'
      ? 'deposit'
      : ''
  const paymentHeader = paymentKind
    ? `${paymentKind[0].toUpperCase()}${paymentKind.slice(1)}`
    : ''

  return (
    <Fragment>
      <div>
        <h3>{paymentHeader} amount</h3>
        <div>${paymentAmount}</div>
      </div>
      <p className={classes.subtext}>
        Proceed with order processing if actual {paymentKind} amount is&nbsp;$
        {paymentAmount} or less.
      </p>
      <div>
        <h3>Credit Card Info</h3>
        <div>
          <Button variant="contained" onClick={handleOpen}>
            Show Credit Card Info
          </Button>
          <Modal open={open} onClose={handleClose} title="Credit Card Info">
            <CreditCard {...creditCard} />
          </Modal>
        </div>
      </div>
      <div>
        <h3>Billing Address</h3>
        <div>Street: {billingAddress.street}</div>
        <div>Apartment: {billingAddress.unit}</div>
        <div>City: {billingAddress.city}</div>
        <div>State: {billingAddress.state}</div>
        <div>Zip Code: {billingAddress.postalCode}</div>
      </div>
    </Fragment>
  )
}

const AdditionalOptions = ({ classes, record: { opt_ins = {} } = {} }) => (
  <Fragment>
    <div>
      <h3>Allow Updater and Xfinity to send me SMS updates about my order.</h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={opt_ins.sms_opt_in}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="Yes, I consent"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={!opt_ins.sms_opt_in}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="No, I do not consent"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
    </div>
  </Fragment>
)

export const OrderView = ({ record, classes, payment, creditCheck }) => (
  <Fragment>
    <Grid item xs={6}>
      <UserInfo record={record} />
    </Grid>
    <Grid item xs={6}>
      <PackageInfo record={record} />
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="Equipment Options" />
        <CardContent>
          <EquipmentOptions record={record} classes={classes} />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="Identity Verification" />
        <CardContent>
          <IdentityVerification creditCheck={creditCheck} classes={classes} />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="Installation Information" />
        <CardContent>
          <InstallationInformation record={record} classes={classes} />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="Billing info" />
        <CardContent>
          <BillingInfo
            payment={payment}
            creditCheck={creditCheck}
            classes={classes}
            price={record.price}
          />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="Additional Options" />
        <CardContent>
          <AdditionalOptions record={record} classes={classes} />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Activity record={record} classes={classes} />
    </Grid>
  </Fragment>
)
