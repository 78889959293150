import React, { Fragment, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Notification, useLogin } from 'react-admin'
import { Grid, Button, Card, CardContent } from '@material-ui/core'

const apiUrl = process.env.UPD_API_URL

const LoginPage = withRouter(({ location: { search } }) => {
  const login = useLogin()

  useEffect(() => {
    const urlParams = new URLSearchParams(search)
    const accessToken = urlParams.get('access_token')
    const uid = urlParams.get('uid')
    const client = urlParams.get('client')
    const edwinJwt = urlParams.get('edwin_jwt')

    if (uid && client && accessToken) {
      login({ uid, client, accessToken, edwinJwt })
    }
  }, [search])

  return (
    <Fragment>
      <Notification />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={4}>
          <Card style={{ marginTop: 50 }}>
            <CardContent
              style={{ justifyContent: 'center', textAlign: 'center' }}
            >
              <Button
                variant="contained"
                color="primary"
                href={`${apiUrl}/v2/sso/google?state=%7B%22app%22%3A%22jarvis%22%2C%22success_redirect_path%22%3A%22%2F%23%2Flogin%22%2C%22error_redirect_path%22%3A%22%2F%23%2Flogin%22%7D`}
                size="large"
              >
                Log in with google
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  )
})

export default LoginPage
