import {
  TopToolbar,
  Datagrid,
  FunctionField,
  List,
  TextField,
  TextInput,
  SelectInput,
  Filter,
  showNotification,
  RefreshButton,
  WithPermissions,
  GET_LIST
} from 'react-admin'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Favico from 'favico.js'
import { updJsonApiDataProvider } from '../../utils/upd-data-provider/dataProvider'
import { getSubscribedHomeServicesOrderStatuses } from '../../utils/permission'
import { STATUSES } from './constants'
import { uniq } from 'lodash'

class HomeServicesOrderList extends Component {
  constructor(props) {
    super(props)
    this.state = { agents: [] }
    this.favico = new Favico({
      animation: 'none'
    })
  }

  componentDidMount() {
    const { showNotification } = this.props

    updJsonApiDataProvider()(GET_LIST, 'home_services_order/agents')
      .then(({ data }) => {
        this.setState({
          agents: data.map(({ id, first_name, last_name }) => ({
            id,
            first_name,
            last_name
          }))
        })
      })
      .catch(() => {
        showNotification(`Cannot fetch agents`)
      })
  }

  render() {
    const ListActions = ({
      filters,
      resource,
      showFilter,
      displayedFilters,
      filterValues
    }) => (
      <TopToolbar>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button'
          })}
        <RefreshButton />
      </TopToolbar>
    )

    const ListFilter = props => (
      <Filter {...props}>
        <TextInput
          label="Reservation Code"
          source="reservation_code"
          defaultValue=""
        />
        <TextInput
          label="First Name"
          source="contact_information.first_name"
          defaultValue=""
        />
        <TextInput
          label="Last Name"
          source="contact_information.last_name"
          defaultValue=""
        />
        <TextInput
          label="Phone"
          source="contact_information.phone"
          defaultValue=""
        />
        <TextInput
          label="Email"
          source="contact_information.email"
          defaultValue=""
        />
        <TextInput label="Source" source="order_source" defaultValue="" />
        <SelectInput
          source="status"
          choices={uniq(Object.values(STATUSES).flatMap(prov => prov.all)).map(
            status => ({
              id: status,
              name: status
            })
          )}
        />
        <SelectInput
          source="agent_id"
          choices={this.state.agents.map(({ id, first_name, last_name }) => ({
            id,
            name: `${first_name} ${last_name}`
          }))}
        />
      </Filter>
    )

    const CustomDataGrid = props => {
      const { ids, data, permissions } = props
      if (!ids.length) return null

      const subscribedStatuses = getSubscribedHomeServicesOrderStatuses(
        permissions
      )
      const subscribedOrders = ids.filter(id =>
        subscribedStatuses.includes(data[id].status)
      )

      this.favico.badge(subscribedOrders.length)

      // Transform record to have agent.name field
      ids.forEach(id => {
        data[id] = {
          ...data[id],
          created_at: new Date(data[id].created_at).toLocaleString(),
          agent: data[id].agent
            ? {
                name: `${data[id].agent.first_name} ${data[id].agent.last_name}`,
                ...data[id].agent
              }
            : null
        }
      })

      return (
        <Datagrid rowClick="edit" {...{ ...props, data }}>
          <TextField
            source="reservation_code"
            label="Reservation Code"
            sortable={false}
          />
          <TextField source="order_source" label="Source" sortable={false} />
          <TextField
            source="offer.provider.name"
            label="Provider"
            sortable={false}
          />
          <TextField
            source="contact_information.first_name"
            label="First Name"
            sortable={false}
          />
          <TextField
            source="contact_information.last_name"
            label="Last Name"
            sortable={false}
          />
          <TextField
            source="contact_information.phone"
            label="Phone"
            sortable={false}
          />
          <TextField
            source="contact_information.email"
            label="Email"
            sortable={false}
          />
          <TextField source="agent.name" label="Agent" sortable={false} />
          <TextField source="status" label="Status" sortable={true} />
          <FunctionField
            label="Status Notes"
            style={{ whiteSpace: 'pre-line' }}
            render={record => {
              if (record.status_reasons) {
                return Object.keys(record.status_reasons)
                  .reduce((accumulator, status) => {
                    const { error_type, note } = record.status_reasons[status]
                    return accumulator.concat(
                      `${status}: ${error_type}${note ? ` - ${note}` : ''}`
                    )
                  }, [])
                  .join('\n')
              } else {
                return ''
              }
            }}
            sortable={false}
          />
          <TextField source="created_at" label="Created Date" />
        </Datagrid>
      )
    }

    return (
      <WithPermissions
        render={({ permissions }) => {
          if (!permissions) return null
          return (
            <List
              {...this.props}
              filters={<ListFilter />}
              actions={<ListActions />}
              bulkActionButtons={false}
              sort={{ field: 'created_at', order: 'DESC' }}
            >
              <CustomDataGrid permissions={permissions} />
            </List>
          )
        }}
      />
    )
  }
}

export default connect(undefined, { showNotification })(HomeServicesOrderList)
