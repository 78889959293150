import React, { Fragment, useState } from 'react'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Radio,
  FormControlLabel,
  Button,
  Checkbox
} from '@material-ui/core'
import Modal from '../modal'
import PackageInfo from '../packageInfo'
import UserInfo from '../userInfo'
import Activity from '../activity'
import { CreditCard } from '../../../credit-card'

const securityQuestionDictionary = {
  hero: 'Who is your favorite childhood hero?',
  restaurant: 'What is your favorite restaurant?',
  singer: 'Who is your favorite singer?',
  actor: 'Who is your favorite actor?'
}

const CreditCheckInfo = ({ classes, creditCheck }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  creditCheck = creditCheck || {}

  return (
    <Fragment>
      <h3>Credit Check</h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="I authorize a credit check via my Social Security Number (SSN)"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        SSN: {creditCheck.ssn ? creditCheck.ssn.substr(-4) : ''}
      </div>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleOpen}>
          Show Full SSN Info
        </Button>
        <Modal open={open} onClose={handleClose} title="Full SSN Info">
          <div>SSN: {creditCheck.ssn}</div>
        </Modal>
      </div>
      <h3>Date of Birth</h3>
      <div>{creditCheck.dateOfBirth}</div>
      <h3>Security Code</h3>
      <div>{creditCheck.passcode}</div>
      <h3>Security Question</h3>
      <div>{securityQuestionDictionary[creditCheck.securityQuestion]}</div>
      <h3>Security Answer</h3>
      <div>{creditCheck.securityAnswer}</div>
      <h4>Verification - Credit Result / Credit Option</h4>
      <div>
        <em>
          Contact customer if a credit management fee is required or a credit
          check fails.
        </em>
      </div>
    </Fragment>
  )
}

const AdditionalOptions = ({
  classes,
  record: { installation = {}, opt_ins = {} } = {}
}) => (
  <Fragment>
    <h3>May AT&T use your contact numbers to reach you by phone or text?</h3>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={opt_ins.sms_opt_in}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="Yes, I consent"
        labelPlacement="end"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={!opt_ins.sms_opt_in}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="No, I do not consent"
        labelPlacement="end"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
    <h3>ConnecTech Support Plus Add-on</h3>
    <div>
      <FormControlLabel
        control={
          <Radio checked={true} classes={{ disabled: classes.disabled }} />
        }
        label="No"
        labelPlacement="end"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
    <h3>Choose type of installation</h3>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={installation.type === 'selfInstall'}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="Self Install (Free)"
        labelPlacement="end"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={installation.type === 'proInstallFree'}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="Professional Install (Free)"
        labelPlacement="end"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
    <div>
      <FormControlLabel
        control={
          <Radio
            checked={installation.type === 'proInstallPaid'}
            classes={{ disabled: classes.disabled }}
          />
        }
        label="Professional Install ($99.00 once)"
        labelPlacement="end"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
    <h3>
      Would you like to split your one-time fees into three equal monthly
      installments?
    </h3>
    <div>
      <FormControlLabel
        control={
          <Radio checked={true} classes={{ disabled: classes.disabled }} />
        }
        label="No, bill me for all one-time charges on my next monthly bill"
        labelPlacement="end"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
    <h3>
      In the future, AT&T may use your email address to ask you a few questions
      about your experience.
    </h3>
    <div>
      <FormControlLabel
        control={
          <Radio checked={true} classes={{ disabled: classes.disabled }} />
        }
        label="No, I would like to opt out of the survey."
        labelPlacement="end"
        disabled
        classes={{ label: classes.label }}
      />
    </div>
  </Fragment>
)

const PromotionsOptions = ({ classes, creditCheck }) => {
  creditCheck = creditCheck || { creditCheckMethod: 'undefined' }

  return (
    <Fragment>
      <h3>
        $5.00 off per month when signing up for Auto Bill Pay and Paperless
        Billing
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={creditCheck.creditCheckMethod === 'autopay'}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="I would like to enroll in Auto Pay Bills and Paperless billing and receive $5 off Internet per month"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={creditCheck.creditCheckMethod === 'manual'}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="I would like to pay manually"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>
        If there is a Rewards Card option, select YES on the customer&apos;s
        behalf.
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="Yes"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>
        If there is an unlimited data upgrade option, select NO on the
        customer&apos;s behalf.
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="No"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>
        If there is an upgrade to a higher speed plan available, select NO on
        the customer&apos;s behalf.
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="No"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>
        If there is a pro-rated monthly discount, select YES on the
        customer&apos;s behalf.
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="Yes"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>If HBO is free, select YES on the customer&apos;s behalf.</h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="Yes"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
    </Fragment>
  )
}

const ShippingInformation = ({
  classes,
  record: { move: { to_address = {} } = {} } = {}
}) => (
  <Fragment>
    <FormControlLabel
      control={
        <Checkbox checked={true} classes={{ disabled: classes.disabled }} />
      }
      label="Same as Service Address"
      labelPlacement="end"
      classes={{ label: classes.label }}
      disabled
    />
    <div>Street: {to_address.street}</div>
    <div>Apartment: {to_address.apt}</div>
    <div>City: {to_address.city}</div>
    <div>State: {to_address.state}</div>
    <div>Zip: {to_address.zip}</div>
  </Fragment>
)

const BillingInfo = ({ payment: { creditCard = {} } = {} }) => {
  const { billingAddress = {} } = creditCard
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <Fragment>
      {creditCard && (
        <div>
          <h3>Credit Card Info</h3>
          <div>
            <Button variant="contained" onClick={handleOpen}>
              Show Credit Card Info
            </Button>
            <Modal open={open} onClose={handleClose} title="Credit Card Info">
              <CreditCard {...creditCard} />
            </Modal>
          </div>
        </div>
      )}
      <div>
        <h3>Billing Address</h3>
        <div>Street: {billingAddress.street}</div>
        <div>Apartment: {billingAddress.unit}</div>
        <div>City: {billingAddress.city}</div>
        <div>State: {billingAddress.state}</div>
        <div>Zip Code: {billingAddress.postalCode}</div>
      </div>
    </Fragment>
  )
}

const InstallationDate = ({ classes, dateNumber, installation }) => {
  // each date is its own property, including windows, so have to access dynamically
  const dateNumberStr = `date${dateNumber}`
  const date = installation[dateNumberStr]
  const installationWindows =
    installation[`${dateNumberStr}_installation_window`] || []
  const firstWindow = installationWindows.some(
    x => x === `${dateNumberStr}InstallationFirstWindow`
  )
  const secondWindow = installationWindows.some(
    x => x === `${dateNumberStr}InstallationSecondWindow`
  )
  return (
    <Fragment>
      <h4>Date {dateNumber}</h4>
      <p>Date: {new Date(date).toLocaleDateString()}</p>
      <p>Time:</p>
      <p>
        <FormControlLabel
          control={
            <Checkbox
              checked={firstWindow}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="8am to 12pm"
          labelPlacement="end"
          classes={{ label: classes.label }}
          disabled
        />
      </p>
      <p>
        <FormControlLabel
          control={
            <Checkbox
              checked={secondWindow}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="12pm to 5pm"
          labelPlacement="end"
          classes={{ label: classes.label }}
          disabled
        />
      </p>
    </Fragment>
  )
}

const InstallationScheduling = ({
  classes,
  record: { installation = {} } = {}
}) => (
  <Fragment>
    <h3>Customer&apos;s preferred installation date & times</h3>
    <p>
      Select the earliest date and time available within AT&T installation
      schedule
    </p>
    <InstallationDate
      classes={classes}
      dateNumber={1}
      installation={installation}
    />
    <InstallationDate
      classes={classes}
      dateNumber={2}
      installation={installation}
    />
    <InstallationDate
      classes={classes}
      dateNumber={3}
      installation={installation}
    />
  </Fragment>
)

const SelfInstallationInfo = () => (
  <Fragment>
    <h4>Customer Activation Date</h4>
    <p>
      Select a date within 2-5 days of the order submission date to activate the
      order.
    </p>
  </Fragment>
)

export const OrderView = ({ record, classes, payment, creditCheck }) => (
  <Fragment>
    <Grid item xs={6}>
      <UserInfo record={record} />
    </Grid>
    <Grid item xs={6}>
      <PackageInfo record={record} />
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader />
        <CardContent>
          <CreditCheckInfo classes={classes} creditCheck={creditCheck} />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="Equipment Options" />
        <CardContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                classes={{ disabled: classes.disabled }}
              />
            }
            label="Internet Equipment Fee is included"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="Additional Options" />
        <CardContent>
          <AdditionalOptions classes={classes} record={record} />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="Promotions Options" />
        <CardContent>
          <PromotionsOptions classes={classes} creditCheck={creditCheck} />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="Shipping Information" />
        <CardContent>
          <ShippingInformation classes={classes} record={record} />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="Terms and Conditions" />
        <CardContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                classes={{ disabled: classes.disabled }}
              />
            }
            label="I agree to the terms and conditions"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardHeader title="Billing info" />
        <CardContent>
          <BillingInfo payment={payment} />
        </CardContent>
      </Card>
    </Grid>
    {record.installation.type !== 'selfInstall' && (
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Installation Scheduling" />
          <CardContent>
            <InstallationScheduling classes={classes} record={record} />
          </CardContent>
        </Card>
      </Grid>
    )}
    {record.installation.type === 'selfInstall' && (
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="AT&amp;T Activation Date" />
          <CardContent>
            <SelfInstallationInfo />
          </CardContent>
        </Card>
      </Grid>
    )}
    <Grid item xs={12}>
      <Activity record={record} classes={classes} />
    </Grid>
  </Fragment>
)
