export async function generateHMAC(keyText, message) {
  // Convert key and message to ArrayBuffers
  const keyBuffer = new TextEncoder().encode(keyText)
  const messageBuffer = new TextEncoder().encode(message)
  // Import the key for HMAC usage
  const cryptoKey = await crypto.subtle.importKey(
    'raw',
    keyBuffer,
    {
      name: 'HMAC',
      hash: { name: 'SHA-256' }
    },
    false, // not extractable
    ['sign'] // allowed operation
  )
  // Compute the HMAC signature
  const signature = await crypto.subtle.sign('HMAC', cryptoKey, messageBuffer)
  // Translate to base64
  const byteArray = Array.from(new Uint8Array(signature))
  const byteString = byteArray.map(b => String.fromCharCode(b)).join('')
  return btoa(byteString)
}
