import { BaseProcessor } from './baseProcessor'

const endpoint = 'managers'

const fields = {
  first_name: { required: true },
  last_name: { required: true },
  email: { required: true },
  password: { required: false },
  parent_id: { required: true, key: 'site_entity_id' },
  home_phone: { required: false },
  cell_phone: { required: false },
  restricted_properties: {
    required: false,
    value: divisionIds,
    key: 'restricted_divisions'
  },
  role: { required: true, key: 'roles', value: roles }
}

const roleMapping = {
  admin: 2,
  'corporate viewer': 7,
  'regional editor': 8,
  'regional viewer': 9,
  'property editor': 10,
  'property viewer': 11
}

function roles(value) {
  if (!value) {
    return
  }
  return [
    {
      id: roleMapping[value]
    }
  ]
}

function divisionIds(value) {
  if (!value) {
    return
  }
  return value
    .toString()
    .split(',')
    .map(id => ({ id }))
}

export default new BaseProcessor(endpoint, fields)
