export const hasPermission = (
  permissions = [],
  requiredPermission,
  requiredActions
) =>
  permissions.filter(
    ({ actions, id }) =>
      id === requiredPermission &&
      requiredActions.filter(a => actions.includes(a)).length ===
        requiredActions.length
  ).length > 0

export const getPermission = (permissions, permission_name) =>
  permissions.find(({ id }) => id === permission_name)

export const getSubscribedHomeServicesOrderStatuses = permissions => {
  const action2Status = {
    processing: 'ready',
    resolving: 'pending'
  }

  const { actions } = getPermission(permissions, 'home_services_order') || {}

  return actions
    .filter(action => Object.keys(action2Status).includes(action))
    .map(action => action2Status[action])
}
