import { updJsonApiDataProvider } from './upd-data-provider/dataProvider'
import {
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_ERROR,
  GET_LIST
} from 'react-admin'

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    const { uid, client, accessToken, edwinJwt } = params
    await sessionStorage.setItem('uid', uid)
    await sessionStorage.setItem('access-token', accessToken)
    await sessionStorage.setItem('client', client)
    await sessionStorage.setItem('edwinJwt', edwinJwt)
    Promise.resolve()
  }
  if (type === AUTH_LOGOUT) {
    await sessionStorage.removeItem('uid')
    await sessionStorage.removeItem('client')
    await sessionStorage.removeItem('access-token')
    await sessionStorage.removeItem('edwinJwt')
    Promise.resolve()
  }
  // Defaults to redirecting to login screen if auth credentials is not present
  if (type === AUTH_CHECK) {
    return (await sessionStorage.getItem('uid')) &&
      (await sessionStorage.getItem('access-token')) &&
      (await sessionStorage.getItem('client'))
      ? Promise.resolve()
      : Promise.reject()
  }
  // Gets called anytime an API call has an error.
  // https://marmelab.com/react-admin/Authentication.html#catching-authentication-errors-on-the-api
  if (type === AUTH_ERROR) {
    const { status, message } = params
    if (
      status === 401 ||
      status === 403 ||
      // check for graphql calls since we don't get status from Apollo.
      message.indexOf('Received status code 401') >= 0
    ) {
      await sessionStorage.removeItem('uid')
      await sessionStorage.removeItem('client')
      await sessionStorage.removeItem('access-token')
      await sessionStorage.removeItem('edwinJwt')
      return Promise.reject()
    }
    return Promise.resolve()
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const uid = await sessionStorage.getItem('uid')
    const accessToken = await sessionStorage.getItem('access-token')
    const client = await sessionStorage.getItem('client')

    if (!uid || !accessToken || !client) return Promise.resolve()

    // const exisitingPermission = JSON.parse(
    //   await localStorage.getItem('permissions')
    // )
    // if (exisitingPermission) return exisitingPermission

    return updJsonApiDataProvider()(GET_LIST, 'sessions').then(
      ({
        data: {
          user: { permissions }
        }
      }) =>
        // await localStorage.setItem('permissions', JSON.stringify(permissions))
        permissions
    )
  }
}
