import React, { Fragment } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import {
  FormDataConsumer,
  BooleanInput,
  NumberInput,
  TextInput
} from 'react-admin'
import { withStyles } from '@material-ui/core'

const style = {
  details: {
    display: 'block'
  }
}

export const ConfigInput = withStyles(style)(({ classes }) => (
  <Fragment>
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        Config
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.details}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData) {
              switch (formData.integration_id) {
                case 3:
                  return (
                    <Fragment>
                      <NumberInput
                        label="account id"
                        source="config.rover.account_id"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                      <TextInput
                        label="property id"
                        source="config.rover.property_id"
                        {...rest}
                        options={{ fullWidth: true }}
                      />
                    </Fragment>
                  )
                  break
                case 440:
                  return (
                    <Fragment>
                      <NumberInput
                        label="PMCID"
                        source="config.rover.pmcid"
                        {...rest}
                      />
                    </Fragment>
                  )
                  break
                case 305:
                  return (
                    <Fragment>
                      <NumberInput
                        label="number of days to pull"
                        source="config.rover.crawl_window"
                        {...rest}
                      />
                    </Fragment>
                  )
                  break
                case 325:
                case 2000:
                case 2157:
                case 2158:
                  return (
                    <Fragment>
                      <TextInput
                        label="subdomain"
                        source="config.rover.subdomain"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                    </Fragment>
                  )
                  break
                case 2001:
                case 2154:
                case 2166:
                case 371:
                case 2153:
                case 2165:
                  return (
                    <Fragment>
                      <TextInput
                        label="domain"
                        source="config.rover.domain"
                        options={{ fullWidth: true }}
                      />
                      <TextInput
                        label="database"
                        source="config.rover.database"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                      <TextInput
                        label="platform"
                        source="config.rover.platform"
                        defaultValue="SQL Server"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                    </Fragment>
                  )
                  break
                case 2006:
                case 2007:
                  return (
                    <Fragment>
                      <TextInput
                        label="domain"
                        source="config.rover.domain"
                        options={{ fullWidth: true }}
                      />
                      <TextInput
                        label="database"
                        source="config.rover.database"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                      <TextInput
                        label="client id"
                        source="config.rover.client_id"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                      <BooleanInput
                        label="SSL verify"
                        source="config.rover.ssl_verify"
                        {...rest}
                      />
                      <TextInput
                        label="partner key"
                        source="config.rover.partner_key"
                        defaultValue="A6F484D6AD9114FCD0F0FE4A69FDBC80EB8FBF5808A066CBE2E5A75D2614932C"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                      <BooleanInput
                        label="static proxy"
                        source="config.rover.static_proxy"
                        {...rest}
                      />
                    </Fragment>
                  )
                  break
                case 2010:
                  return (
                    <Fragment>
                      <TextInput
                        label="endpoint"
                        source="config.rover.endpoint"
                        options={{ fullWidth: true }}
                      />
                      <TextInput
                        label="portfolio"
                        source="config.rover.portfolio"
                        options={{ fullWidth: true }}
                        {...rest}
                      />
                    </Fragment>
                  )
                  break
                case 2013:
                  return (
                    <Fragment>
                      <TextInput
                        label="company id"
                        source="config.rover.company_id"
                        options={{ fullWidth: true }}
                      />
                      <NumberInput
                        label="transaction delta in hours"
                        source="config.rover.transactions_delta_hours_ago"
                        {...rest}
                      />
                      <TextInput
                        label="company id"
                        source="config.moves_process_job.transform.config.company_id"
                        options={{ fullWidth: true }}
                      />
                    </Fragment>
                  )
                  break
                case 2025:
                  break
                case 2042:
                  break
              }
            }
          }}
        </FormDataConsumer>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </Fragment>
))
