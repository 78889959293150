import React, { Fragment } from 'react'
import {
  Create,
  Datagrid,
  Edit,
  List,
  TextField,
  TextInput,
  DateField,
  SelectField,
  SelectInput,
  SimpleForm
} from 'react-admin'
import { ConfigInput } from './fragments/configs'
import { VaultInput } from './fragments/vault_params'
import { withStyles } from '@material-ui/core'

const inputStyle = {
  config_container: {
    'margin-top': '1em',
    padding: '1em',
    'box-shadow': '0px 1px 3px 0px rgba(0, 0, 0, 0.2)'
  },
  config_title: {
    color: 'rgba(0, 0, 0, 0.87)',
    'margin-bottom': '1em'
  }
}

const Integrations = [
  { id: 3, name: 'Resman - Pending and Approved without Cancellation)' },
  { id: 5, name: 'Resman - Approved with Cancellation)' },
  { id: 440, name: 'RealPage 3.0' },
  { id: 2007, name: 'MRI V2' },
  { id: 2010, name: 'AMSI' },
  { id: 2013, name: 'Dash' },
  { id: 2153, name: 'Yardi 7s - Approved Applicants' },
  { id: 2154, name: 'Yardi 7s - Lease Signed' },
  { id: 2165, name: 'Yardi 7s - Approved Applicants Bidirectional' },
  { id: 2166, name: 'Yardi 7s - Lease Signed Bidirectional' },
  { id: 2157, name: 'Entrata - with Leads' },
  { id: 2158, name: 'Entrata - without Leads' },
  {
    id: 2165,
    name: 'Yardi 7s API - Approved Applicants - With Cancellation - Bidirection'
  },
  {
    id: 2166,
    name: 'Yardi 7s - Lease Signed - With Cancellation - Bidirectional'
  }
]

const Schedules = [
  { id: 'daily', name: '8pm' },
  { id: 'paused', name: 'Pause' },
  { id: null, name: 'None' }
]

export const EntityconfigurationList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <SelectField
        source="integration_id"
        label="Integration"
        choices={Integrations}
      />
      <TextField label="Entity ID" source="entity_id" />
      <TextField source="email_token" label="email token" />
      <TextField source="rover_schedule" label="Rover Schedule" />
      <TextField source="config" />
      <TextField source="is_legacy" />
      <TextField source="is_argo" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
)

const EntityConfigurationInput = withStyles(inputStyle)(({ record }) => (
  <Fragment>
    <ConfigInput record={record} />
    <VaultInput record={record} />
  </Fragment>
))

export const EntityConfigurationCreate = props => (
  <Create title="Create Entity Integration" {...props}>
    <SimpleForm>
      <TextInput source="entity_id" label="company id" reference="entities" />
      <SelectInput
        source="integration_id"
        label="Integration"
        choices={Integrations}
        options={{ fullWidth: true }}
      />
      <SelectInput
        source="rover_schedule"
        label="Rover Schedule"
        choices={Schedules}
        options={{ fullWidth: true }}
      />
      <TextInput source="email_token" label="email token" />
      <TextInput source="is_legacy" defaultValue={0} label="is legacy?" />
      <TextInput source="is_argo" defaultValue={1} label="is argo?" />
      <EntityConfigurationInput />
    </SimpleForm>
  </Create>
)

export const EntityconfigurationEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" label="entity configuration id" />
      <SelectInput
        source="integration_id"
        label="Integration"
        choices={Integrations}
      />
      <SelectInput
        source="rover_schedule"
        label="Rover Schedule"
        choices={Schedules}
        options={{ fullWidth: true }}
      />
      <TextInput source="email_token" label="email token" />
      <TextInput source="entity_id" label="company id" />
      <TextInput source="is_legacy" label="is legacy?" />
      <TextInput source="is_argo" label="is argo?" />
      <EntityConfigurationInput />
    </SimpleForm>
  </Edit>
)
