import React, { Fragment, useState } from 'react'
import {
  Grid,
  Card,
  CardContent,
  Radio,
  FormControlLabel,
  Button,
  Checkbox
} from '@material-ui/core'
import Modal from '../modal'
import PackageInfo from '../packageInfo'
import UserInfo from '../userInfo'
import Activity from '../activity'

const securityQuestionDictionary = {
  firstPet: 'What is the name of your first pet?',
  thirdGradeCity: 'What city did you lived on in 3rd grade?',
  firstGradeTeacher: 'What was the name of your first grade teacher?',
  cityParentsMet: 'What is the name of the city that your parents met?',
  grandmotherMaidenName: "What is your maternal grandmother's maiden name?",
  school: 'What school did you attend when you were 10 years old?',
  firstConcert: 'What was the first concert that you attended?'
}

/* Equipment */
const Equipment = ({ classes, record }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <h3>Choose your equipment</h3>
      <CardContent>
        {record.installation.internet_modem_choice === 'internetRental' && (
          <div>
            <FormControlLabel
              control={
                <Radio
                  checked={
                    record.installation.internet_modem_choice ===
                    'internetRental'
                  }
                  classes={{ disabled: classes.disabled }}
                />
              }
              label={`${record.price.items['internet_rental'].label} (${record.price.items['internet_rental'].price_description})`}
              labelPlacement="end"
              classes={{ label: classes.label }}
              disabled
            />
          </div>
        )}
        <div>
          <FormControlLabel
            control={
              <Radio
                checked={
                  record.installation.internet_modem_choice ===
                  'internetModemOwned'
                }
                classes={{ disabled: classes.disabled }}
              />
            }
            label="I'll use my own"
            labelPlacement="end"
            classes={{ label: classes.label }}
            disabled
          />
        </div>
      </CardContent>
      <h3>Streaming so simple: Contour stream player</h3>
      <CardContent>
        <FormControlLabel
          control={
            <Checkbox checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="0"
          labelPlacement="end"
          classes={{ label: classes.label }}
          disabled
        />
      </CardContent>
    </Card>
  </Fragment>
)

/* Contract Length */
const ContractLength = ({ classes, record }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <h3>Contract Length</h3>
      <CardContent>
        <div>
          <FormControlLabel
            control={
              <Radio checked={record.price.plan.contract_term === 'oneYear'} />
            }
            label="1 Year term agreement"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Radio checked={record.price.plan.contract_term === 'twoYear'} />
            }
            label="2 Year term agreement"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Radio checked={record.price.plan.contract_term === 'remove'} />
            }
            label="Remove term agreement (+$10.00)"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>
      </CardContent>
    </Card>
  </Fragment>
)
/* Credit Check-SSN-Contact */
const CreditCheckInfo = ({ classes, creditCheck }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  creditCheck = creditCheck || {}

  return (
    <Fragment>
      <h3>Credit Check</h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="I authorize a credit check"
          labelPlacement="end"
          classes={{ label: classes.label }}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        SSN: {creditCheck.ssn ? creditCheck.ssn.substr(-4) : ''}
      </div>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleOpen}>
          Show Full SSN Info
        </Button>
        <Modal open={open} onClose={handleClose} title="Full SSN Info">
          <div>SSN: {creditCheck.ssn}</div>
        </Modal>
      </div>
      <h3>Have you lived at your current address for 6 months or longer?</h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={
                creditCheck.hasLivedAtCurrentAddressForMoreThan6Months === 'Yes'
              }
            />
          }
          label="Yes"
          labelPlacement="end"
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={
                creditCheck.hasLivedAtCurrentAddressForMoreThan6Months !== 'Yes'
              }
            />
          }
          label="No"
          labelPlacement="end"
          classes={{ label: classes.label }}
        />
      </div>
      {creditCheck.hasLivedAtCurrentAddressForMoreThan6Months === 'No' && (
        <Fragment>
          <div>{creditCheck.priorAddress.address.street}</div>
          <div>
            {creditCheck.priorAddress.address.city},{' '}
            {creditCheck.priorAddress.address.state}{' '}
            {creditCheck.priorAddress.address.postalCode}
          </div>
        </Fragment>
      )}
      <h3>Contact me by</h3>
      <div>
        <FormControlLabel
          control={<Radio checked={true} />}
          label="Email"
          labelPlacement="end"
          classes={{ label: classes.label }}
        />
      </div>
      <p>Verification - Credit Results / Credit Options</p>
      <p>
        Contact consumer if they fail the credit results and will be required to
        pay a $50.00 deposit to go forward.
      </p>
    </Fragment>
  )
}

/* PIN-Security-DOB */
const Security = ({ creditCheck }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <h3>Create a Cox Pin</h3>
        <div>
          <p>{creditCheck.passcode}</p>
        </div>

        <h3>Show pin on bill?</h3>
        <div>
          <p>Yes</p>
        </div>

        <h3>Security Question</h3>
        <div>
          <p>{securityQuestionDictionary[creditCheck.securityQuestion]}</p>
        </div>

        <h3>Security Answer</h3>
        <div>
          <p>{creditCheck.securityAnswer}</p>
        </div>

        <h3>Date of Birth</h3>
        <div>
          <p>{creditCheck.dateOfBirth}</p>
        </div>
      </CardContent>
    </Card>
  </Fragment>
)

/* Installation */
const Installation = ({ classes }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <h3>Installation Options</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Self Activation"
            labelPlacement="end"
            disabled
            classes={{ label: classes.label }}
          />
        </div>
      </CardContent>
    </Card>
  </Fragment>
)
/* Additional Questions */
const OtherQuestions = ({ classes }) => (
  <Fragment>
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <h3>Emergency Broadband Relief</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="No"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>Internet Terms and Conditions</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>Terms and Conditions of Service</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>1 Year Term Agreement</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>Easy Pay Agreement</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>

        <h3>Monthly Total Agreement</h3>
        <div>
          <FormControlLabel
            control={
              <Radio checked={true} classes={{ disabled: classes.disabled }} />
            }
            label="Yes"
            labelPlacement="end"
            classes={{ label: classes.label }}
          />
        </div>
      </CardContent>
    </Card>
  </Fragment>
)

/* Main constructed component */
export const OrderView = ({ record, classes, creditCheck }) => (
  <Fragment>
    <Grid item xs={6}>
      <UserInfo record={record} />
    </Grid>
    <Grid item xs={6}>
      <PackageInfo record={record} />
    </Grid>

    <Grid item xs={12}>
      <Equipment classes={classes} record={record} />
    </Grid>

    <Grid item xs={12}>
      <ContractLength classes={classes} record={record} />
    </Grid>
    <Grid item xs={12}>
      <Card style={{ marginTop: 20 }}>
        <CardContent>
          <CreditCheckInfo classes={classes} creditCheck={creditCheck} />
        </CardContent>
      </Card>
    </Grid>
    <Grid item xs={12}>
      <Security creditCheck={creditCheck} />
    </Grid>
    <Grid item xs={12}>
      <Installation classes={classes} />
    </Grid>
    <Grid item xs={12}>
      <OtherQuestions classes={classes} />
    </Grid>

    <Grid item xs={12}>
      <Activity record={record} classes={classes} />
    </Grid>
  </Fragment>
)
