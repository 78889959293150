const TOKEN_URL = 'https://v8vy0byloh.execute-api.us-east-1.amazonaws.com/test/'

export const deleteTokenData = async token => {
  try {
    await fetch(`${TOKEN_URL}/delete-token?token=${token}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return {}
  } catch {
    return {}
  }
}

/**
 *
 * @param {string} token
 * @returns {Promise<import('./types').DTSPayload>}
 */
export const getTokenData = async token => {
  try {
    const response = await fetch(`${TOKEN_URL}/get-token?token=${token}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()

    return JSON.parse(json)
  } catch {
    return {}
  }
}

export const getPreviousErrorType = ({ statuses, statusReasons }) => {
  const status = statuses.pending.find(status => statusReasons[status])
  const { error_type } = statusReasons[status] || {}

  return error_type
}
