import { CloneButton } from 'react-admin'
import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'

export const UpdaterCloneButton = ({
  record = {},
  omitFields = [],
  ...rest
}) => <CloneButton record={omit(record, omitFields)} {...rest} />

UpdaterCloneButton.propTypes = {
  record: PropTypes.object,
  omitFields: PropTypes.array
}
