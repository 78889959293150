import { useMemo } from 'react'
import useScript from 'react-script-hook'

const scriptConfig =
  process.env.NODE_ENV === 'production'
    ? {
        src: 'https://htp.tokenex.com/Iframe/Iframe-v3.41.min.js',
        integrity:
          'sha384-dJY8nUeyNGP4iXGrTCsZMIFQub7oULC94WxlotldgWAEmWGeF8tNArSwl873YF6G',
        crossorigin: 'anonymous'
      }
    : {
        src: 'https://test-htp.tokenex.com/Iframe/iframe-v3.41.min.js',
        integrity:
          'sha384-eePjvZ5S0b9RUZx7RkGNcH8blvSQdw09HkWDyhPrMyfJDD/4lWjRRJ0HCSKI0gqr',
        crossorigin: 'anonymous'
      }

export function useTokenexIframeScript() {
  const [loading, error] = useScript(scriptConfig)

  return useMemo(
    () => ({ status: loading ? 'pending' : error ? 'failed' : 'done' }),
    [loading, error]
  )
}
